// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blogger-tsx": () => import("./../../src/pages/blogger.tsx" /* webpackChunkName: "component---src-pages-blogger-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookbook-spice-and-rice-tsx": () => import("./../../src/pages/cookbook/spice-and-rice.tsx" /* webpackChunkName: "component---src-pages-cookbook-spice-and-rice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-peanutbutterandjillybeans-tsx": () => import("./../../src/pages/peanutbutterandjillybeans.tsx" /* webpackChunkName: "component---src-pages-peanutbutterandjillybeans-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

