module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"240229424052268"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-116002540-1"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://11999e9ac2334d2b9631dd5b542e77fe@sentry.io/1398983","environment":"production","enabled":true,"release":"1.4.106"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
